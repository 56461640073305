<template>
  <b-card-actions
    ref="cardAction"
    title="Ürün Listesi"
    icon="ShoppingCartIcon"
    :showLoading="show"
    :actionCollapse="true"
    :actionRefresh="true"
    :actionNewAdd="true"
    :collapsed="true"
    @refresh="refreshStop('cardAction')"
    @newAdd="handlerNewAdd($event)"
  >
    <!-- actionCollapse true ise filtre kısmı calısırız -->
    <template v-slot:filtrele>
      <b-row class="my-1 mx-25">
        <b-col cols="12" md="3">
          <b-form-input
            v-model="filter.arama"
            class="mr-1 rounded-0"
            placeholder="Arama..."
            @keyup.enter="handlerFilter"
            v-b-tooltip.hover.top="'Aranacak kelimeyi yazdıktan sonra ENTER\'a basınız.'"
          />
        </b-col>
        <b-col cols="12" md="3">
          <v-select
            v-model="filter.urun_kategori_k_no"
            :options="urunKategoriler"
            :reduce="(menu) => menu.k_no"
            label="baslik"
            :clearable="true"
            placeholder="Kategori Göre"
            @input="handlerFilter"
          />
        </b-col>
        <b-col cols="12" md="3">
          <v-select
            v-model="filter.kategori_k_no"
            :options="kategoriler"
            :reduce="(kategoriler) => kategoriler.k_no"
            label="baslik"
            :clearable="true"
            placeholder="Menü Kategoriye Göre"
            @input="handlerFilter"
          />
        </b-col>
        <b-col cols="12" md="3">
          <v-select
            v-model="filter.statu"
            :options="statuData"
            :reduce="(statuData) => statuData.statu"
            label="title"
            :clearable="false"
            placeholder="Statu"
            @input="handlerFilter"
          />
        </b-col>
      </b-row>
    </template>
    <template v-slot:body>
      <vue-good-table
        :columns="columns"
        :rows="rows.value"
        :line-numbers="true"
        :pagination-options="{
          enabled: true,
          perPage: 100,
        }"
        styleClass="vgt-table striped bordered"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'tarih'">
            <router-link
              :to="{
                name: 'urun-guncelle',
                params: { k_no: props.row.k_no },
              }"
            >
              {{ props.row.save_date | momentFull }}
            </router-link>
          </span>
          <span v-else-if="props.column.field === 'baslik'">
            <router-link
              :to="{
                name: 'urun-guncelle',
                params: { k_no: props.row.k_no },
              }"
            >
              {{ props.row.icerik[defaultDil].baslik }}
            </router-link>
          </span>
          <span v-else-if="props.column.field === 'dil'">
            <b-badge :variant="langColor(dil)" class="rounded-0 mr-25" v-for="(dil, i) in props.row.icerik" :key="i">
              {{ i }}
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'kategori'">
            <b-badge variant="primary" class="rounded-0" v-if="props.row.kategori != null">
              {{ props.row.kategori[defaultDil].baslik }}
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'urun_kategori'">
            <b-badge variant="primary" class="rounded-0" v-if="props.row.urun_kategori != null">
              {{ props.row.urun_kategori[defaultDil].baslik }}
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'fiyat'">
            <b-badge variant="primary" class="rounded-0" v-if="props.row.fiyat != null">
              {{ props.row.fiyat | cur }} {{ props.row.kur }}
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'save_user'">
            <b-badge variant="success" class="rounded-0" v-if="props.row.save_user != null">
              {{ props.row.save_user | str_limit(10) }}
            </b-badge>
            <b-badge variant="warning" class="rounded-0" v-if="props.row.edit_user != null">
              {{ props.row.edit_user | str_limit(10) }}
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'statu'">
            <b-form-checkbox
              class="custom-control-info"
              v-model="props.row.statu"
              switch
              @change="handlerStatu(props.row._id, $event)"
            >
              <span class="switch-icon-left">
                <feather-icon icon="WifiIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="WifiOffIcon" />
              </span>
            </b-form-checkbox>
          </span>
          <span v-else-if="props.column.field === 'anasayfa'">
            <b-form-checkbox
              class="custom-control-info"
              v-model="props.row.anasayfa"
              switch
              @change="handlerAnasayfa(props.row.k_no, $event)"
            >
              <span class="switch-icon-left">
                <feather-icon icon="WifiIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="WifiOffIcon" />
              </span>
            </b-form-checkbox>
          </span>
          <span v-else-if="props.column.field === 'action'" class="float-right px-1">
            <b-button-group size="sm" class="pb-25">
              <b-button
                class="rounded-0"
                variant="warning"
                :to="{
                  name: 'urun-guncelle',
                  params: { k_no: props.row.k_no },
                }"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button variant="danger" class="rounded-0" @click="handlerRemove(props.row.k_no)">
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-button-group>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <v-select
                v-model="filter.pageSize"
                :options="perPageOptions"
                :clearable="false"
                class="invoice-filter-select"
                @input="handlerPageSizeChange"
              />
              <span class="pl-2">
                Toplam Kayıt: <strong> {{ count }}</strong>
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="count"
                :per-page="filter.pageSize"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handlerPageChange"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </template>
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import { VueGoodTable } from 'vue-good-table';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';

import { ref, computed } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
import Swal from 'sweetalert2';
import { useRouter } from '@core/utils/utils';
export default {
  components: {
    BCardActions,
    VueGoodTable,
    vSelect,
  },

  setup() {
    const expo = {};
    const { router } = useRouter();
    const toast = useToast();
    // const diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.show = ref(false);
    expo.closeModal = ref(false);
    expo.updateData = ref({});
    expo.openModal = ref(false);
    expo.perPageOptions = ref([10, 25, 50, 100]);
    expo.kategoriler = ref([]);
    expo.urunKategoriler = ref([]);
    expo.columns = ref([
      {
        label: 'Tarih',
        field: 'tarih',
        width: '10%',
      },
      {
        label: 'Başlık',
        field: 'baslik',
      },
      {
        label: 'Menü Kategori',
        field: 'kategori',
        width: '10%',
        tdClass: 'text-right',
      },
      {
        label: 'Urun Kategori',
        field: 'urun_kategori',
        width: '10%',
        tdClass: 'text-right',
      },
      {
        label: 'Fiyat',
        field: 'fiyat',
        width: '10%',
        tdClass: 'text-right',
      },
      {
        label: 'Ekli Diller',
        field: 'dil',
        width: '12%',
        // thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'Ekl. & Gün.',
        field: 'save_user',
        width: '7%',
        tdClass: 'text-right',
      },
      {
        label: 'Statu',
        field: 'statu',
        width: '5%',
        tdClass: 'text-right',
      },
      {
        label: 'Anasayfa',
        field: 'anasayfa',
        width: '5%',
        tdClass: 'text-right',
      },
      {
        label: 'İşlemler',
        field: 'action',
        width: '10%',
      },
    ]);
    expo.rows = ref([]);
    expo.count = ref(0);
    expo.filter = ref({
      arama: null,
      kategori_k_no: null,
      urun_kategori_k_no: null,
      statu: true,
      page: 1,
      pageSize: 25,
    });
    expo.statuData = ref([
      {
        statu: true,
        title: 'Aktif',
      },
      {
        statu: false,
        title: 'Pasif',
      },
    ]);

    expo.handlerNewAdd = (event) => {
      router.push({ name: 'urun-ekle' });
    };
    expo.handlerDigerDataGet = async () => {
      expo.show.value = true;
      await store.dispatch('kategoriListele');
      await store.dispatch('urunKategoriListele');
      const kat = await store.getters.getKategoriler;
      const urunKat = await store.getters.getUrunKategoriler;
      kat.forEach((el) => {
        expo.kategoriler.value.push({ k_no: el.k_no, baslik: el.icerik[expo.defaultDil.value].baslik });
      });

      urunKat.forEach((el) => {
        expo.urunKategoriler.value.push({ k_no: el.k_no, baslik: el.icerik[expo.defaultDil.value].baslik });
      });
      expo.show.value = false;
    };

    expo.handlerGetData = async () => {
      expo.show.value = true;

      const params = getRequestParams(
        expo.filter.value.arama,
        expo.filter.value.kategori_k_no,
        expo.filter.value.urun_kategori_k_no,
        expo.filter.value.statu,
        expo.filter.value.page,
        expo.filter.value.pageSize
      );
      await store.dispatch('urunListele', params).then((res) => {
        if (res.data.success == true) {
          expo.rows = res.data.data;
          expo.count.value = res.data.count;
        }
      });
      expo.show.value = false;
    };

    const getRequestParams = (arama, kategori_k_no, urun_kategori_k_no, statu, page, pageSize) => {
      let params = {};
      if (arama) {
        params['arama'] = arama;
      }
      if (kategori_k_no) {
        params['kategori_k_no'] = kategori_k_no;
      }
      if (urun_kategori_k_no) {
        params['urun_kategori_k_no'] = urun_kategori_k_no;
      }
      params['statu'] = statu;

      if (page) {
        params['page'] = page - 1;
      }
      if (pageSize) {
        params['pageSize'] = pageSize;
      }
      return params;
    };

    expo.handlerDigerDataGet();
    expo.handlerGetData();

    expo.rows.value = computed(() => store.getters.getUrunler);

    expo.handlerStatu = async (k_no, event) => {
      if (k_no != null) {
        await axiosIns.post('urun/urun-statu-guncelle', { k_no, statu: event }).then((res) => {
          if (res.data.success === true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: 'Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
      }
    };
    expo.handlerAnasayfa = async (k_no, event) => {
      if (k_no != null) {
        await axiosIns.post('urun/urun-anasayfa-guncelle', { k_no, anasayfa: event }).then((res) => {
          if (res.data.success === true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: 'Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
      }
    };
    expo.handlerFilter = () => {
      expo.handlerGetData();
    };

    expo.handlerPageSizeChange = (event) => {
      expo.filter.value.pageSize = event;
      expo.handlerGetData();
    };

    expo.handlerPageChange = (event) => {
      expo.filter.value.page = event;
      expo.handlerGetData();
    };
    expo.refreshStop = (event) => {
      expo.handlerGetData();
    };
    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('urunSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Silme`,
                    icon: 'CheckSquareIcon',
                    variant: 'danger',
                    text: 'Silme Başarılı',
                  },
                },
                { position: POSITION.BOTTOM_RIGHT }
              );
            }
          });
        }
      });
    };
    expo.langColor = (value) => {
      return value.baslik != null ? 'success' : 'danger';
    };

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba($black, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
